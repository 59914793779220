import React, { FC } from 'react';
import styled from 'styled-components';

const LeaveTipWrapper = styled.div`

`;

const LeaveTip: FC = () => (
	<LeaveTipWrapper>aaaa</LeaveTipWrapper>
);
export default LeaveTip;
